/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"stano_oslava"}>
        <Column className="css-8mtnf5 css-42e4bw --full" name={"uvod"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/a87c712fc5694ce1a4a3919317bf3066_s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--10 flex--bottom" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5 --shadow3" anim={"6"} animS={"5"} style={{"maxWidth":1080,"backgroundColor":"rgba(109,97,26,0.13)"}}>
              
              <Title className="title-box title-box--justify fs--72 w--300 lh--12" style={{"maxWidth":1000}} content={"<br><br><br><br>„Iba život, ktorý žijeme pre ostatných, stojí za to.“&nbsp;<span style=\"color: white;\"><br><br></span>"}>
              </Title>

              <Text className="text-box text-box--right fs--43" style={{"maxWidth":751}} content={"Albert Einstein<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--80 pt--80" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1270}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"Přišiel čas oslavovať — chcem, <br>aby ste boli pri tom"}>
              </Title>

              <Text className="text-box text-box--left" style={{"maxWidth":744}} content={"Dlho som rozmýšlal ako stráviť moje druhé narodeniny..<br>ako ich urobiť neformálne, spojiť rodinu a priateľov&nbsp;na krásnom mieste, <br>s možnosťou zábavy, relaxu, zaspomínania na spoločné chvíle <br>možno trocha v retro štýle, a preto som vybral miesto netradičné, no verím, že na konci pre každého príjemné...<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--10" name={"informace"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s5 --center el--2 pl--0 pr--0 flex--center" anim={"4"} animS={"5"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37183/4b2054128d7543e68ee9285c482e4453_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37183/4b2054128d7543e68ee9285c482e4453_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37183/4b2054128d7543e68ee9285c482e4453_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37183/4b2054128d7543e68ee9285c482e4453_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37183/4b2054128d7543e68ee9285c482e4453_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/37183/4b2054128d7543e68ee9285c482e4453_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Kde?"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/o/11212/6971990f1bb746ea926852ad0369724b.svg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":200}} srcSet={""}>
              </Image>

              <Text className="text-box text-box--center" content={"Chata Magurka - na osade Magurka. <br>banícka hostina, večerná grilovačka, skvelá muzika, wellnes s kaďou a saunou, cigary, rumík, výborné vínko. a ráno? spoločné raňajky, spoločná túra, možno after party..., a po celý čas verím skvelá pohoda a zábava.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--50" name={"informace-2"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s5 --center el--2 pb--50 pl--0 pr--0 flex--center" anim={"5"} animS={"5"} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Kedy?"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/o/11212/6971990f1bb746ea926852ad0369724b.svg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":200}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">25-26.5.2024</span><br>v čase od 10-14 hod. voľný príchod, ubytovanie. 15.00 oficiálny výkop. Doprava bicyklom alebo autom. kto bude potrebovať dovoz, bude zabezpečený. To isté platí pri odvoze. Samozrejme celá chata vrátane izieb je naša, kto však nebude chcieť spať, môže počítať, že bude večer naložený a odvezený..<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Vinarsvi/vinarstvi-5.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-11w66ws --full pb--60 pt--60" name={"paticka"} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=350x_.JPEG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=660x_.JPEG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=860x_.JPEG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=1400x_.JPEG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=2000x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=660x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=1400x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=3000x_.JPEG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s5 --center el--1 flex--center" anim={"6"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" content={"<span style=\"color: white;\">Těšíme se na Vás.</span>"}>
              </Title>

              <Button className="btn-box" use={"page"} href={"/potvrdit-ucast"} style={{"backgroundColor":"rgba(145,102,16,1)"}} content={"Potvrdit účast"}>
              </Button>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"stano_oslava - Potvrdit účast"}>
        <Column className="pb--80 pt--80" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--60" anim={"2"} animS={"5"} style={{"maxWidth":1270}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"Verím, že prídete, a potvrdenie je len formalita, <br>každopádne prosím pár info"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":916}} content={"Pozvanie samozrejme platí aj pre partnera. Prosím o info či budete spať, alebo pre vás večer počítať s odvozom, prípadne aj dovozom, a teda či za tie roky ste sa nestali vegetariánom, vegánom, alkoholikom, alebo abstinentom...:)<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"8qrbnox2vog"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--30" style={{"maxWidth":1100}} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 --shadow3 fs--20 w--300 lh--2" style={{"maxWidth":1686}} action={"/contact"} fields={[{"name":"Meno ","type":"text","required":true,"placeholder":"priezvisko"},{"name":"s partnerom?","type":"text","required":true,"placeholder":""},{"name":"doprava ?","type":"text","required":true,"placeholder":""},{"name":"ubytovanie ?","type":"textarea","required":true},{"name":"Odoslať","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-11w66ws --full pb--60 pt--60" name={"paticka"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=350x_.JPEG);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=660x_.JPEG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=860x_.JPEG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=1400x_.JPEG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=2000x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=660x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=1400x_.JPEG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37183/e97bc7ebe78145b29d9a28d5f77ac67b_s=3000x_.JPEG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim6 --anim-s5 --center el--1 flex--center" anim={"6"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86" content={"<span style=\"color: white;\">Těšíme se na Vás.</span>"}>
              </Title>

              <Button className="btn-box" href={"/"} content={"Späť na prvú stranu<br>"}>
              </Button>

              <Text className="text-box" content={"<span style=\"color: white;\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}